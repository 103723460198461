import { RouteRecordRaw } from 'vue-router';

/**
 * 路由meta对象参数说明
 * meta: {
 *      title:          菜单栏及 tagsView 栏、菜单搜索名称（国际化）
 *      isLink：        是否超链接菜单，开启外链条件，`1、isLink:true 2、链接地址不为空`
 *      isHide：        是否隐藏此路由
 *      isKeepAlive：   是否缓存组件状态
 *      isAffix：       是否固定在 tagsView 栏上
 *      isIframe：      是否内嵌窗口，，开启条件，`1、isIframe:true 2、链接地址不为空`
 *      roles：         当前路由权限标识，取角色管理。控制路由显示、隐藏。超级管理员：admin 普通角色：common
 *      icon：          菜单、tagsView 图标，阿里：加 `iconfont xxx`，fontawesome：加 `fa xxx`
 * }
 */

/**
 * 定义动态路由
 * @description 未开启 isRequestRoutes 为 true 时使用（前端控制路由），开启时第一个顶级 children 的路由将被替换成接口请求回来的路由数据
 * @description 各字段请查看 `/@/views/system/menu/component/addMenu.vue 下的 ruleForm`
 * @returns 返回路由菜单数据
 */
export const dynamicRoutes: Array<RouteRecordRaw> = [
    {
        path: '/',
        name: '/',
        component: () => import('/@/layout/index.vue'),
        redirect: '',
        meta: {
            isKeepAlive: true,
        },
        children: [
            {
                path: '/home',
                name: 'home',
                component: () => import('/@/views/home/index.vue'),
                meta: {
                    title: 'message.router.home',
                    isLink: '',
                    isHide: false,
                    isKeepAlive: true,
                    isAffix: true,
                    isIframe: false,
                    roles: ['admin', 'common'],
                    icon: 'iconfont icon-shouye',
                },
            },
        ],
    },

    {
        path: '/401',
        name: 'noPower',
        component: () => import('/@/views/error/401.vue'),
        meta: {
            isLink: false,
            title: 'message.staticRoutes.noPower',
        },
    },
];

/**
 * 定义静态路由
 * @description 前端控制直接改 dynamicRoutes 中的路由，后端控制不需要修改，请求接口路由数据时，会覆盖 dynamicRoutes 第一个顶级 children 的内容（全屏，不包含 layout 中的路由出口）
 * @returns 返回路由菜单数据
 */
export const staticRoutes: Array<RouteRecordRaw> = [
    {
        path: '/login',
        name: 'login',
        component: () => import('/@/views/login/index.vue'),
        meta: {
            title: '登录',
        },
    },
    {
        path: '/loginHandler',
        name: 'loginHandler',
        component: () => import('/@/views/loginHandler/index.vue'),
        meta: {
            title: '登录',
        },
    },
    {
        path: '/404',
        name: 'notFound',
        component: () => import('/@/views/error/404.vue'),
        meta: {
            title: 'message.staticRoutes.notFound',
        },
    },
    {
        path: '/401',
        name: 'noPower',
        component: () => import('/@/views/error/401.vue'),
        meta: {
            title: 'message.staticRoutes.noPower',
        },
    },
    {
        path: '/pbdbox',
        name: 'pbdBox',
        component: () => import('/@/views/pbd/pbdBox/index.vue'),
        meta: {
            title: 'message.staticRoutes.noPower',
        },
    },
    {
        path: '/parents',
        name: 'parents',
        component: () => import('/@/views/parents/index.vue'),
        meta: {
            title: '心理云平台',
        },
        children: [
            {
                path: 'menu',
                name: 'menu',
                component: () => import('/@/views/parents/menu.vue'),
            },
            {
                path: 'bind',
                name: 'bindStudent',
                component: () => import('/@/views/parents/bindStudent.vue'),
            },
            {
                path: 'form',
                name: 'bindForm',
                component: () => import('/@/views/parents/bindForm.vue'),
            },
            {
                path: 'report',
                name: 'studentReport',
                component: () => import('/@/views/parents/report.vue'),
            },
            {
                path: 'notice',
                name: 'notice',
                component: () => import('/@/views/parents/notice.vue'),
            },
            {
                path: 'survey',
                name: 'parentSurvey',
                component: () => import('/@/views/parents/survey.vue'),
            },
            {
                path: 'family',
                name: 'family',
                component: () => import('/@/views/parents/family.vue'),
            },
            {
                path: ':id',
                name: 'reportDetail',
                component: () => import('/@/views/parents/reportDetail.vue'),
            },
            {
                path: 'feedback',
                name: 'feedback',
                component: () => import('/@/views/parents/feedback.vue'),
            },
            {
                path: 'feedback/:studentId',
                name: 'feedbackContent',
                component: () => import('/@/views/parents/feedbackContent.vue'),
            },
            {
                path: '',
                name: 'menu',
                component: () => import('/@/views/parents/menu.vue'),
            }
        ]
    },

    {
        path: '/:catchAll(.*)',
        component: () => import('/@/views/error/404.vue'),
    },
    {
        path: '/record/imagecheck',
        name: 'imageCheck',
        component: () => import('/@/views/pbd/pbdRecord/imageCheck.vue'),
        meta: {
            title: 'imageCheck',
        },
    },
    // {
    //     path: '/showtime/home',
    //     name: 'showtime',
    //     component: () => import('/@/views/showtime/home/index.vue'),
    //     meta: {
    //         title: '心理健康监测预警系统',
    //     }
    // },

    {
        path: '/mhp/home',
        name: 'mhpHome',
        component: () => import('/@/views/mhp/home.vue'),
        redirect: '/mhp/home/frontPage',
        meta: {
            title: '心理健康大数据平台',
        },
        children: [
            {
                path: 'frontPage',
                name: 'frontPage',
                component: () => import('/@/views/mhp/frontPage.vue'),
                meta: {
                    title: '心理健康大数据平台',
                },
            },
            {
                path: 'index',
                name: 'mhpIndex',
                component: () => import('/@/views/mhp/index.vue'),
                meta: {
                    title: '心理健康大数据平台',
                },
            },
            {
                path: 'showtime',
                name: 'showtime',
                component: () => import('/@/views/showtime/home/index.vue'),
                meta: {
                    title: '情绪识别',
                },
            },
        ],
    },
    {
        path: '/plb/plbStudentDo/evaluation',
        name: 'plbStuDoEvaluation',
        component: () => import('/@/views/plb/plbStudentDo/evaluation.vue'),
        meta: {
            title: '学生测评',
        },
    },
    {
        path: '/earlyWarning/warnReport',
        name: 'warnReport',
        component: () => import('/@/views/earlyWarning/home/component/warnReport.vue'),
        meta: {
            title: '预警报告',
        },
    },
];
