import { Module } from 'vuex';
import { Session } from '/@/utils/common/storage';
import { UserInfosState, RootStateTypes } from '/@/store/interface/index';

const userInfosModule: Module<UserInfosState, RootStateTypes> = {
    namespaced: true,
    state: {
        userInfos: {
            authBtnList: [],
            photo: '',
            roles: [],
            time: 0,
            userName: '',
        },
    },
    mutations: {
        // 设置用户信息
        getUserInfos(state, data: any) {
            state.userInfos = data;
        },
    },
    actions: {
        // 设置用户信息
        async setUserInfos({ commit }, data: UserInfosState) {
            if (data) {
                this.dispatch('routesList/setRoutesList', null);
                commit('getUserInfos', data);
            } else {
                if (Session.get('userInfo')) {
                    this.dispatch('routesList/setRoutesList', null);
                    commit('getUserInfos', Session.get('userInfo'));
                }
            }
        },
    },
};

export default userInfosModule;
