import request from '/@/utils/request';

/**
 * 获取路由列表
 * @param params
 */
export function routerList(data:any) {
	return request({
		url: '/sysMenu/routerList',
		method: 'post',
		data,
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
        },
	});
}
